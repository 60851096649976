import React from "react";
import AccountBanner from "../../components/AccountBanner"
import { getRequest } from "sharedUtils/httpUtils";
import CampaignReport from "./CampaignReport";
import BestSellersReport from "./BestSellersReport"
import CommissionReconcileReport from "./CommissionReconcileReport"
class Reports extends React.Component {

    state = {
        selectedCase: 1,
    }

    breadCrumbList = [{ link: '/Admin', text: 'Admin' }, { link: '/Admin/Reports', text: 'Reports' }];
    campaignReportBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Reports', text: 'Campaign Report', active: true }
    ];
    bestSellersReportBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Reports', text: 'Best Sellers Report', active: true }
    ];

    commissionReconcileReportBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Reports', text: 'Commission Reconcile Report', active: true }
    ];

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }

    displayToggleButtons = () => {
        let options = [
            {
                name: "Campaign Report",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
            {
                name: "Best Sellers Report",
                case: 2,
                action: () => this.setState({ selectedCase: 2 })
            },
            {
                name: "Commission Reconcile Report",
                case: 3,
                action: () => this.setState({ selectedCase: 3 })
            },
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    render() {
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.state.selectedCase === 1 ? this.campaignReportBreadCrumbList : this.state.selectedCase === 2 ? this.bestSellersReportBreadCrumbList : this.state.selectedCase == 3 ? this.commissionReconcileReportBreadCrumbList : this.breadCrumbList}>
                        <h1 className="mb-4">Reports</h1>
                        <p className="mb-0">Administrative Reporting Tools</p>
                    </AccountBanner>
                </section>
                <section className="filter-wrapper" >
                    <div className="container">
                        <div className="row py-2 mt-0">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="filter-block d-flex">
                                    {this.displayToggleButtons()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="gray-bg full-height pt-1">
                    <CampaignReport
                        display={this.state.selectedCase === 1}
                        loggedInUser={this.props.loggedInUser}
                    />
                    <BestSellersReport
                        display={this.state.selectedCase === 2}
                        loggedInUser={this.props.loggedInUser}
                    />
                    <CommissionReconcileReport
                        display={this.state.selectedCase === 3}
                        loggedInUser={this.props.loggedInUser}
                    />
                </section>
            </>
        )
    }
}

export default Reports;