import React from "react";

/**
 * 
 * @param {{fill:string, height?:string, width?:string}} props
 */
const CloudDownloadIcon = (props) => {
    return (
        <svg
            class="cloud-download-icon"
            xmlns="http://www.w3.org/2000/svg"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            imageRendering="optimizeQuality"
            fillRule="evenodd"
            clipRule="evenodd"
            viewBox="0 0 512 512"
            height={props.height || "24"}
            width={props.width || "24"}
            onClick={props.onClick}
        >
            <path
                className="cloud-download-icon"
                fillRule="nonzero"
                d="M377.763 115.7c-9.421 2.733-18.532 6.86-27.592 12.155-9.256 5.41-18.373 12.03-27.649 19.628l-19.848-22.742c16.719-15.527 33.187-26.463 49.108-33.513-13.06-22.39-31.538-38.532-52.418-48.549-21.339-10.239-45.243-14.172-68.507-11.922-23.123 2.234-45.56 10.619-64.123 25.025-21.451 16.646-37.775 41.521-44.035 74.469l-1.957 10.309-10.271 1.801c-27.993 4.909-49.283 18.792-62.859 36.776-7.186 9.518-12.228 20.161-14.969 31.19-2.728 10.979-3.193 22.398-1.243 33.524 3.291 18.767 13.592 36.737 31.669 50.382 5.467 4.129 11.376 7.709 17.885 10.482 6.214 2.645 13.017 4.61 20.559 5.685h44.24v30.245h-44.809l-1.891-.178c-11.101-1.413-20.985-4.187-29.914-7.989-8.995-3.831-16.991-8.652-24.264-14.142-24.619-18.584-38.692-43.317-43.247-69.287-2.669-15.224-2.027-30.868 1.715-45.928 3.73-15.013 10.524-29.404 20.167-42.177 16.233-21.507 40.499-38.514 71.737-46.241 9.014-35.904 28.299-63.574 53.056-82.786C171.438 13.963 199.327 3.521 228.021.748c28.551-2.76 57.973 2.109 84.338 14.758 28.096 13.479 52.661 35.696 68.986 66.814 13.827-2.2 27.043-1.52 39.421 1.501 18.862 4.603 35.492 14.61 49.211 28.159 13.361 13.192 23.994 29.797 31.217 48.001 16.813 42.377 15.208 93.979-13.362 131.996-9.3 12.37-21.252 22.449-35.572 30.468-13.811 7.735-29.886 13.593-47.949 17.786l-3.368.414h-32.329V310.4h30.711c14.499-3.496 27.298-8.213 38.167-14.3 10.795-6.045 19.621-13.397 26.238-22.199 21.843-29.066 22.745-69.341 9.463-102.816-5.697-14.358-13.998-27.37-24.362-37.604-10.007-9.882-21.907-17.127-35.154-20.36-6.655-1.624-13.721-2.248-21.143-1.705l-14.771 4.284zM182.06 330.8c-5.288-6.392-4.394-15.861 1.997-21.148 6.391-5.288 15.86-4.394 21.148 1.997l33.15 40.027.202-146.582c0-8.273 6.707-14.98 14.98-14.98 8.274 0 14.981 6.707 14.981 14.98l-.202 146.627 34.287-41.255c5.288-6.359 14.731-7.227 21.09-1.939 6.358 5.288 7.226 14.73 1.938 21.089l-60.071 72.279a15.056 15.056 0 01-2.707 2.921c-6.391 5.288-15.86 4.394-21.148-1.997L182.06 330.8z"
            />
        </svg>
    );
}

export default CloudDownloadIcon;
